'use client';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import { ErrorFallback } from 'shared/utilities/ErrorBoundary';

type GlobalErrorProps = {
    error: Error & { digest?: string };
    reset: () => void;
};

export default function GlobalError({ error, reset }: GlobalErrorProps) {
    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);
    return (
        <html lang="ru">
            <body>
                <ErrorFallback error={error} resetError={reset} />
            </body>
        </html>
    );
}
